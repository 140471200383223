<template>
    <div>
        <app-layout>
	        <template v-slot:header>
                <Header :title="$t('year')"
                        :isNewButton="checkPermission('year_store')"
                        @new-button-click="add"
                        @filter-div-status="datatable.filterStatus = $event">
                </Header>
	        </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6" md="4" lg="3" xl="2">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox v-model="datatable.queryParams.filter.academic_year">
                            </academic-years-selectbox>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                :total="datatable.total" :queryParams="datatable.queryParams" :lineNumbers="false"
                @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable">
            </datatable>
            <CommonModal ref="modal" size="md">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t(id == 0 ? 'add' : 'edit') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div class="row mb-5 align-items-center">
                        <div class="col-12">
                            <ValidationObserver ref="storeForm">
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="academic_year" rules="required"
                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('academic_year')">
                                                <academic-years-selectbox v-model="form.academic_year">
                                                </academic-years-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12">
                                        <ValidationProvider name="receipt_number_format" rules="required"
                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('receipt_number_format')">
                                                <b-form-input v-model="form.receipt_number_format">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12">
                                        <ValidationProvider name="receipt_number" rules="required"
                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('receipt_number')">
                                                <b-form-input type="number" v-model="form.receipt_number">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12">
                                        <ValidationProvider name="start_date" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('start_date')">
                                                <b-form-input type="date" v-model="form.start_date">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12">
                                        <ValidationProvider name="end_date" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('end_date')">
                                                <b-form-input type="date" v-model="form.end_date">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>



                                </div>
                            </ValidationObserver>
                        </div>
                        <div class="col-12 mt-3 d-flex">
                            <b-button @click="store" type="button" variant="primary" class="btn-lg mr-2">
                                {{ $t('save') }}
                            </b-button>
                        </div>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import qs from 'qs'
import YearService from "@/services/YearService";
import CommonModal from "@/components/elements/CommonModal";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import AcademicYearsSelectbox from '@/components/interactive-fields/AcademicYearsSelectbox.vue';

export default {
    components: {
        AppLayout,
        Header,
        DatatableFilter,
        Datatable,
        CommonModal,
        ValidationProvider,
        ValidationObserver,
        AcademicYearsSelectbox
    },
    metaInfo() {
        return {
            title: this.$t('Year')
        }
    },
    data() {
        return {
            id: 0,
            form: {},
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('edit'),
                                class: 'ri-edit-box-line',
                                permission: "year_update",
                                callback: (row) => {
                                    this.id = row.id;
                                    this.loadData();
                                }
                            },
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-2-line',
                                permission: "year_delete",
                                callback: (row) => {
                                    this.delete(row.id);
                                }
                            },
                        ]
                    },
                    {
                        label: this.$t('code'),
                        field: 'code',
                        sortable: true,
                    },

                    {
                        label: this.$t('academic_year'),
                        field: 'academic_year',
                        sortable: true,
                    },
                    {
                        label: this.$t('receipt_number'),
                        field: 'receipt_number',
                        sortable: true,
                    },
                    {
                        label: this.$t('start_date'),
                        field: 'start_date',
                        sortable: true,
                    },
                    {
                        label: this.$t('end_date'),
                        field: 'end_date',
                        sortable: true,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {
                    },
                    sort: 'code',
                    page: 1,
                    limit: 20
                }
            }
        }
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {
                academic_year: null
            };
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            return YearService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data

                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        loadData() {
            if (this.id > 0) {
                YearService.get(this.id)
                    .then(response => {
                        this.$refs.storeForm.reset();
                        this.form = response.data.data;
                        this.$refs.modal.$refs.commonModal.show();
                    }).catch(error => {
                        if (error.data.message) {
                            this.$toast.error(this.$t('api.' + error.data.message));
                        }
                    });
            }
        },
        async store() {
            const isValid = await this.$refs.storeForm.validate()
            if (isValid) {
                if (this.id == 0) {
                    YearService.store(this.form, this.value)
                        .then((response) => {
                            this.$toast.success(this.$t("api." + response.data.message));
                        })
                        .catch((error) => {
                            this.showErrors(error)
                        })
                }
                else {
                    YearService.update(this.id, this.form)
                        .then((response) => {
                            this.$toast.success(this.$t("api." + response.data.message));
                        })
                        .catch((error) => {
                            this.showErrors(error)
                        })
                }

            }
        },
        showErrors(error) {
            if (error.status == 422) {

                if (error.data.errors.academic_year) {
                    this.$refs.storeForm.errors.academic_year.push(error.data.errors.academic_year[0]);
                }

                if (error.data.errors.receipt_number_format) {
                    this.$refs.storeForm.errors.receipt_number_format.push(error.data.errors.receipt_number_format[0]);
                }
                if (error.data.errors.receipt_number) {
                    this.$refs.storeForm.errors.receipt_number.push(error.data.errors.receipt_number[0]);
                }
                if (error.data.errors.start_date) {
                    this.$refs.storeForm.errors.start_date.push(error.data.errors.start_date[0]);
                }
                if (error.data.errors.end_date) {
                    this.$refs.storeForm.errors.end_date.push(error.data.errors.end_date[0]);
                }
            }
            else if (error.status == 406) {
                this.$toast.error(this.$t('api.' + error.data.message));
            }
        },
        delete(id) {
            this.deleteModal(() => {
                YearService.deleteYear(id)
                    .then(response => {
                        this.filter();
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(error => {
                        this.$toast.error(this.$t('api.' + error.data.message));
                    });
            });
        },
        add() {
            this.id = 0;
            this.$refs.storeForm.reset();
            this.form = {};
            this.$refs.modal.$refs.commonModal.show();
        }
    }
};
</script>

